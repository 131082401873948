.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
}
#overlays {
    display: flex;
    justify-content: center;
}
.modal {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/

    position: fixed;
    top: 12vh;
    left: 8%;
    width: fit-content;


    /*height: auto;*/
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 300;
    /*background-attachment: fixed;*/
    /*animation: slide-down 300ms ease-out forwards;*/
}

.container_modal {
    display:flex;
    flex-direction: row;
    position: fixed;
    top: 20vh;
    left: 8%;
    width: fit-content;
    z-index: 300;
    scrollbar-color: #2bfece white;
    scrollbar-border-radius: 5px;

}

.content {
    /*width: 20vw;*/
    /*background-color: clear;*/
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-border-radius: 5px;
    border-radius: 15px;
}

.container {

    display:flex;
    flex-direction: row;

    position: fixed;
    top: 12vh;
    left: 8%;
    width: auto;
}