.zapleczeMapaUserAndAdmin {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #fafafc;
    /*background-color: white;*/
    justify-content: space-between;
    overflow-y: auto;
    max-width: 100%;
    overflow-x: hidden;

}

.verticalLeftSideBar {
    top: 0px;
    display: flex;
    flex-direction: column;
    background-color: blue;
    /*background-color: white;*/
    position: sticky;

    /*height: 100vh;*/
    width: 17.5vw;
    justify-self: start;
}


.verticalMenuLeftSideBar {
    width: 100%;
    background-color: green;
}

.middlePart {
    display: flex;
    flex-direction: column;
    background-color: #fafafc;
    /*background-color: white;*/
    height: 100vh;
    width: 52.5vw;
}

.middlePartAdmin {
    display: flex;
    flex-direction: column;
    background-color: #fafafc;
    height: 100vh;
    /*width: 52.5vw;*/
    width: 100%;
    margin-left: 5%;
}

.middlePart__label {
    background-color: #fafafc;
    position: relative;
    height: 41px;
    margin: 49px 458.2px 51px 54px;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.31px;
    text-align: left;
    color: #363636;
    display: flex;
    width: 100%;
}

.middlePart__label_billing {
    background-color: #fafafc;
    position: relative;
    /*height: 100%;*/
    margin: 49px 458.2px 51px 54px;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.31px;
    text-align: left;
    color: #363636;
    display: flex;
    width: 100%;
}

.mapContainer {
    display: flex;
    flex-direction: column;
    /*background-color: magenta;*/
    /*background-color: #fafafc;*/

    height: 100%;
    width: 23.3vw;

}