
@media only screen and  (min-width: 1280px) and (max-width: 1920px) {
    .Container  {
        display: grid;
        min-height: 100vh;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto  auto;
        row-gap: 1em;
        grid-template-areas:
         
    "nav nav nav nav"
    "inputsearch inputsearch inputsearch inputsearch"
    "resultcontainer resultcontainer maptotal maptotal"
    "footer footer footer footer";
    }

    /*#appcontainer {*/
    /*    display: grid;*/
    /*    min-height: 100vh;*/
    /*    grid-template-columns: 1fr 1fr 1fr 1fr;*/
    /*    grid-template-rows: auto auto  auto;*/
    /*    row-gap: 1em;*/
    /*    grid-template-areas:*/
    /*"nav nav nav nav"*/
    /*"inputsearch inputsearch inputsearch inputsearch"*/
    /*"resultcontainer resultcontainer maptotal maptotal"*/
    /*"footer footer footer footer";*/
    /*}*/
}

/*@media only screen and  (max-width: 1280px)  {*/
/*    .Container {*/
/*        display: grid;*/
/*        min-height: 100vh;*/
/*        grid-template-columns: 1fr 1fr 1fr 1fr;*/
/*        grid-template-rows: auto 1fr auto;*/
/*        grid-template-areas:*/
/*    "nav nav nav nav"*/
/*    "inputsearch inputsearch inputsearch inputsearch"*/
/*    "resultcontainer resultcontainer resultcontainer resultcontainer"*/
/*    "footer footer footer footer";*/
/*    }*/
/*    }*/



.Container > footer {

    grid-area: footer;
    margin-top: auto;
}

footer {
    margin-top: auto;
    position: sticky;
}
