.Path-1231 {
    width: 21.8px;
    height: 27.2px;
    background-color: #01d1b5;
  }

.Path-1230 {
  width: 32.6px;
  height: 32.6px;
  margin: 25.4px 5.4px 24px 0;
  padding: 2.7px 5.4px 2.7px 5.4px;
  background-color: rgba(0, 0, 0, 0);
  color: #01d1b5

}

  .-Input-text {
    width: 61px;
    height: 17px;
    margin: 0.6px 299px 31px 4.4px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.13px;
    text-align: left;
    color: #363636;
  }

  .-Input-text .text-style-1 {
    color: #02bea5;
  }

.-Input-text_1920 {
  width: 206px;
  height: 46px;
  margin: 0 243px 36px 80px;
  padding: 11.7px 155.9px 10.9px 32.6px;
  font-family: Lato;
  font-size: 38px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 6.84px;
  text-align: left;
  color: #363636;
}

.-Input-text_1920 .text-style-1 {
  color: #02bea5;
}