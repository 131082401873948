.Rectangle-2876 {
  /*446px;*/
  width: 100%;
  height: 70px;
  margin: auto;
  padding: 8px 9px 8px 16px;
  border-radius: 45px;
  background-color: white;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: auto;

}
.Rectangle-2876_bad {
  /*446px;*/
  width: 100%;
  height: 70px;
  margin: auto;
  padding: 8px 9px 8px 16px;
  border-radius: 45px;
  background-color: white;
  border: 2px solid red;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: auto;

}

.Rectangle-2876 > span:hover,
.Rectangle-2876 > span:active
{
  cursor: auto;
}

.-Input-text-1 {
  width: 71px;
  height: 17px;
  margin: 2px 304px 2px 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.13px;
  text-align: left;
  color: #363636;

  cursor: auto;

}

.-Col-left {
  width:100px;

}

.-Col-right {
  width:100px;

}
/*73px ;*/
.-Input-text-2 {
  width: 100%;
  height: 40%;
  margin: 2px 302px 1px 0;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.13px;
  text-align: left;
  color: #363636;
  border: none;
  /*background-color: transparent;*/
}

/*.Ellipse-88 {*/
/*  width: 40px;*/
/*  height: 40px;*/
/*  margin: 0 0 0 0px;*/
/*  padding: 1px 1px 1px 1px;*/
/*  color: #01d1b5;*/
/*  background-color: #fff;*/
/*  !*cursor: pointer;*!*/
/*}*/

  input {
    border: none;
    /*background-color: transparent;*/
    resize: none;
    outline: none;
}

/*446px;*/
.Column-Conteiner2 {
  display: flex;
  width: 90%;
  flex-direction: column;
  
}

