.overlays {
    display: flex;
    justify-content: center;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 19781031;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    position: fixed;
    top: 2vh;
    /*left: 10%;*/
    width: 90%;
    /*height: 35vh;*/
    /*width: fit-content;*/
    height: fit-content;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 19790517;
    /*animation: slide-down 300ms ease-out forwards;*/
}

.buttons {
    display: flex;
    justify-content: flex-end;

}

@media (min-width: 768px) {
    .modal {
        width: 40rem;
        left: calc(50% - 20rem);
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}