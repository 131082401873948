.PointClassM {
    width: 86px;
    height: 17px;
    margin: 24px 176px 16px 0px;
    font-family: Lato;
    font-size: 13px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.13px;
    text-align: left;
    color: #363636;
}

.PointClass {
    width: 86px;
    height: 17px;
    margin: 24px 176px 16px 43px;
    font-family: Lato;
    font-size: 13px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.13px;
    text-align: left;
    color: #363636;
}

.LeftHeader001Main {
    width: 329px;
    height: 41px;
    margin: 33px 423px 48px 79px;
    font-family: Lato;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: 0.31px;
    text-align: left;
    color: #363636;
}



.LeftHeader001 {
    width: 177px;
    height: 17px;
    margin: 24px 116px 24px 12px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.13px;
    text-align: left;
    color: #363636;
}



@media (min-width: 1920px) {
    .Inputtext {
        width: 329px;
        height: 41px;
        margin: 51px 913px 48px 82px;
        font-family: Lato;
        font-size: 34px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.53;
        letter-spacing: 0.31px;
        text-align: left;
        color: #363636;
    }

    .Rectangle3111 {
        width: 514px;
        height: auto;
        margin: 51px 82px 156px;
        padding: 40px 69px 43px 35px;
        border-radius: 24px;
        box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
    }

}



@media (min-width: 769px) and (max-width: 1279px) {

.Rectangle3111 {
    display: none;
}
    .BlokDefinicje {
        width: 568px;
        height: 757px;
        margin: 32px 100px 0;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
        align-self: center;
    }

    .Definicje {
        width: 568px;
        height: 757px;
        margin: 32px 100px 0;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }


    .LeftHeader001Main {
        width: 232px;
        height: 29px;
        margin: 40px 436px 32px 100px;
        font-family: Lato;
        font-size: 22px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: 0.22px;
        text-align: left;
        color: #363636;
        align-self: center;
    }


    .PointClassM {
        width: 376px;
        height: 17px;
        margin: 24px 176px 16px 0px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }


}

@media (min-width: 0px) and  (max-width: 768px) {

    .Rectangle3111 {
        display: none;
    }

    .LeftHeader001Main {
        width: 232px;
        height: 29px;
        margin: 40px 436px 32px 100px;
        font-family: Lato;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: 0.22px;
        text-align: left;
        color: #363636;
    }


    .BlokDefinicje {
        width: 328px;

        height: 441px;
        margin: 32px 16px 0;
        font-family: Lato;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
        align-self: center;
    }

    .Definicje {
        width: 328px;
        height: 441px;
        margin: 32px 16px 0;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }



    }



    .PointClassM {
        width: 276px;
        height: 17px;
        margin: 24px 176px 16px 0px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }
    

@media (min-width: 1280px) {

    .LeftHeader001Main {
        width: 329px;
        height: 41px;
        margin: 33px 423px 48px 79px;
        font-family: Lato;
        font-size: 32px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.53;
        letter-spacing: 0.31px;
        text-align: left;
        color: #363636;
    }

    .LeftHeader001Main2 {
        width: 177px;
        height: 17px;
        margin: 24px 116px 24px 12px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .LeftHeader001Main3 {
        width: 220px;
        height: 17px;
        margin: 32px 79px 24px 6px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass8 {
        width: 86px;
        height: 17px;
        margin: 24px 182px 16px 37px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
        textDecoration: 'underline';
    }

    .PointClass7 {
        width: 228px;
        height: 35px;
        margin: 16px 34px 32px 43px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass6 {
        width: 107px;
        height: 17px;
        margin: 16px 155px 16px 43px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass5 {
        width: 261px;
        height: 35px;
        margin: 16px 1px 16px 43px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass4 {
        width: 254px;
        height: 35px;
        margin: 16px 8px 16px 43px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass3 {
        width: 234px;
        height: 35px;
        margin: 16px 28px 16px 43px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass2 {
        width: 239px;
        height: 35px;
        margin: 16px 23px 16px 43px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClass1 {
        width: 170px;
        height: 17px;
        margin: 16px 92px 16px 43px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }


    .Ellipse262 {
        width: 6px;
        height: 6px;
        margin: 30px 6px 29px 0;
        background-color: #363636;
    }

    .InputtextLeft1 {
        width: 312px;
        height: 17px;
        margin: 8px 0 24px 5px;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
        display: none;
    }

    .InputtextLeft0 {
        width: 104px;
        height: 22px;
        margin: 0 208px 8px 5px;
        font-family: Lato;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: 0.16px;
        text-align: left;
        color: #363636;
    }


    .Inputtext {
        width: 329px;
        height: 41px;
        margin: 33px 423px 48px 79px;
        font-family: Lato;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.53;
        letter-spacing: 0.31px;
        text-align: left;
        color: #363636;
        /*background-color: green;*/
    }

    .Rectangle3111 {
        width: 369px;
        height: auto;
        margin: 21px 79px 0 80px;
        padding: 24px 31px 15px 21px;
        border-radius: 24px;
        box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
    }

    .Definicje {
        width: 576px;
        height: 592px;
        margin: 48px 176px 0 79px;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }


    .BlokDefinicje {
        width: 600px;
        height: 757px;
        margin: 32px 100px 0;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }

    .PointClassM {
        width: 476px;
        height: 17px;
        margin: 24px 176px 16px 0px;
        font-family: Lato;
        font-size: 13px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.13px;
        text-align: left;
        color: #363636;
    }


}
