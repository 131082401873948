.search__section__input {
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: #f2f3f5;
    z-index:500;
    position: -webkit-sticky;
    position: sticky;
    top: 1vh;
    grid-area: inputsearch;
    /*zIndex: 1;*/
    padding-bottom: 5%;
    border: 5px dotted #36af28;
}

.full_container_test {
    display: flex;
    justify-content:space-between;
    margin: 5rem 0 0 0;
}

.full_container_test > div {
    display: flex;
    flex:2;
    justify-content: center;
}

.full_container_test > container {
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.searchinput {
    /*flex-basis: 65%;*/
    /*justify-content: center;*/
    /*border: 5px dotted red;*/
}

.search__section__sidebar_container {
flex-basis: 15%;
}

@media only screen and  (min-width: 1280px) and (max-width: 1920px) {
    .map {

        width: 448px;
        height:  405px;
        margin: 25px 82px 7px 32px;
    padding: 138.3px 246.4px 225.1px 230.5px;
     position: sticky;
        top: calc(1em + 12vh);
        border-radius: 24px;
    }

    .maptotal {
        grid-area: maptotal;
        position: -webkit-sticky;
        position: sticky;
        top:  12vh;
        width: 600px;
        height: 575px;
        border-radius: 24px;
        margin: 25px 80px 24px 32px;
        /*padding: 82.3px 176.4px 165.1px 116.5px;*/
    }

    .mapcontainer {
        display: flex;
        align-items: end;
        /*height: 100%;*/
        /*width: 100%;*/
        position: -webkit-sticky;
        position: sticky;
        top: 10vh;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        /*margin: 33px 80px 25px 32px;*/

    }

    .mapcontainer__container {
        width: 448px;
        height: 405px;

        /*width: 100%;*/
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        position: -webkit-sticky;
        position: sticky;
        overflow: hidden;
        /*margin: 33px 80px 25px 32px;*/
    }


    .mapcontainer__container {
        /*width: 448px;*/
        height: 575px;

        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        position: -webkit-sticky;
        position: sticky;
        overflow: hidden;
        /*margin: 33px 80px 25px 32px;*/
    }

}

@media only screen and   (max-width: 1279px) {

    .map {
        display: none
    }

    .maptotal {
        grid-area: maptotal;
        position: -webkit-sticky;
        position: sticky;
        /*top:  22vh;*/
        /*width: 100%;*/
        /*height: 405px;*/
        border-radius: 24px;
        margin: 25px 80px 24px 32px;
        /*padding: 82.3px 176.4px 165.1px 116.5px;*/
    }

    .mapcontainer {
        display: flex;
        align-items: end;
        /*height: 100%;*/
        /*width: 100%;*/
        position: -webkit-sticky;
        position: sticky;
        /*top: 10vh;*/
        justify-content: flex-start;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        /*margin: 33px 80px 25px 32px;*/

    }

    .mapcontainer__container {
        width: 100%;
        height: 405px;
        /*width: 100%;*/
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        position: -webkit-sticky;
        position: sticky;
        overflow: hidden;
        /*margin: 33px 80px 25px 32px;*/
    }

}

@media only screen and   (min-width: 1280px) {
    .search__results_container {
        display: flex;
        /*position: relative;*/
        /*top: 12vh;*/
        justify-content: flex-start;
        /*align-items: flex-start;*/
        grid-area: resultcontainer;
        margin-left: 6.25vw;
        /*padding-left: 8vw;*/
        /*padding-right: 2.5vw;*/
        /*//6.25vw;*/

    }
}
@media only screen and   (max-width: 1280px) {
    .search__results_container {
        display: flex;
        justify-content: center;
        grid-area: resultcontainer;
        /*padding-left: 6.25vw;*/
        /*padding-right: 2.5vw;*/


    }
}

.search__results_items_container {
    display: flex;

    flex-direction: row;
    background-color: #66bf3c;
    flex-basis: 75%;
    justify-content: start;
}

.themap {
    border-radius: 24px;
    border: 5px dotted #66f805;
    width: 50%;
}

@media only screen and  (min-width: 1280px)  {

    .Container  {
        display: grid;
        min-height: 100vh;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto  auto;
        row-gap: 1em;
        grid-template-areas:
    "nav nav nav nav"
    "inputsearch inputsearch inputsearch inputsearch"
    "resultcontainer resultcontainer maptotal maptotal"
    "footer footer footer footer";
    }

    #appcontainer {
        display: grid;
        min-height: 100vh;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto  auto;
        row-gap: 1em;
        grid-template-areas:
    "nav nav nav nav"
    "inputsearch inputsearch inputsearch inputsearch"
    "resultcontainer resultcontainer maptotal maptotal"
    "footer footer footer footer";
    }
}
.Container_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}